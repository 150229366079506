$(document).on('turbolinks:load', function () {
  let $customer = $('#invoice_customer_id')
  $customer.select2({
    ajax: {
      url: $customer.data('remote-url'),
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term,
          page: params.page || 1,
          ds: true
        }
        return query;
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: data.results,
          pagination: {
            more: data.pagination
          }
        };
      }
    }
  });

  let $deal = $('select#invoice_kylas_deal_id')
  $deal.select2({
    ajax: {
      url: $deal.data('remote-url'),
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term,
          ds: true
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data
        };
      }
    }
  });
  let $invoice_vendor_tax_id = $('select#invoice_vendor_tax_id')
  $invoice_vendor_tax_id.select2({
    ajax: {
      url: $invoice_vendor_tax_id.data('remote-url'),
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term,
          ds: true
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data
        };
      }
    }
  });
  $('#invoice_form .fields').each(function () {
    lineItemsAdjustor($(this));
  });
});

$(document).on('nested:fieldAdded', function (event) {
  var $fields = event.field;
  lineItemsAdjustor($fields);
});

$(document).on('nested:fieldRemoved', function (event) {
  var $fields = event.field;
  $fields.find('input, select, textarea').removeAttr('required');
});

var lineItemsAdjustor = function ($fields) {
  let $product_dropdown = $fields.find('.kylas_product_id');
  $product_dropdown.select2({
    ajax: {
      url: $product_dropdown.data('remote-url'),
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term,
          ds: true
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data
        };
      }
    }
  }).on('select2:select', function (e) {
    var data = e.params.data;
    if (data.price && data.price.value > 0) {
      $fields.find('.unit_price').val(data.price.value).trigger('change');
    }
  });

  let $vendor_product_dropdown = $fields.find('.vendor_product_id');
  $vendor_product_dropdown.select2({
    ajax: {
      url: $vendor_product_dropdown.data('remote-url'),
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term,
          ds: true
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data
        };
      }
    }
  }).on('select2:select', function (e) {
    var data = e.params.data;
    if (data) {
      $fields.find('.product_name').val(data.text);
    }
  });

  let $vendor_tax_dropdown = $fields.find('.vendor_tax_id');
  $vendor_tax_dropdown.select2({
    ajax: {
      url: $vendor_tax_dropdown.data('remote-url'),
      dataType: 'json',
      data: function (params) {
        var query = {
          search: params.term,
          ds: true
        }
        return query;
      },
      processResults: function (data) {
        return {
          results: data
        };
      }
    }
  }).on('select2:select', function (e) {
    var data = e.params.data;
    if (data) {
      $fields.find('.tax_name').val(data.text);
    }
  });

  $fields.find('.unit_price').on('keyup change', function () {
    calculateTotalBasedOnUnitPrice($(this));
  });

  $fields.find('.amount').on('keyup change', function () {
    calculateTotalBasedOnAmount($(this));
  });

  let quantityFields = $fields.find('.quantity');
  if (quantityFields.length >= 1){
    $.each(quantityFields, function (_index, element) {
      calculateTotalBasedOnQuantity($(element));
    })
  }

  quantityFields.on('keyup change', function () {
    calculateTotalBasedOnQuantity($(this));
  });
}

var show_total = function () {
  let sum = 0;
  $('.amount').each(function () {
    if (!isNaN(parseFloat($(this).val()))){
      sum += parseFloat($(this).val());
    }
  });
  $('.invoice-total-amount').html(sum);
}

var calculateTotalBasedOnUnitPrice = function(element) {
  let $field = element.closest('.fields');
  let unit_price = parseFloat(element.val());
  let quantity = $field.find('.quantity').val();
  if (unit_price > 0 && quantity > 0) {
    $field.find('.amount').val(quantity * unit_price);
  }
  show_total();
}

var calculateTotalBasedOnAmount = function(element) {
  let $field = element.closest('.fields');
  let amount = parseFloat(element.val());
  let quantity = $field.find('.quantity').val();
  if (amount > 0 && quantity > 0) {
    $field.find('.unit_price').val(amount / quantity);
  }
  show_total();
}

var calculateTotalBasedOnQuantity = function(element) {
  let $field = element.closest('.fields');
  let quantity = parseFloat(element.val());
  let unit_price = $field.find('.unit_price').val();
  if (unit_price > 0 && quantity > 0) {
    $field.find('.amount').val(quantity * unit_price);
  }
  show_total();
}
