// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("bootstrap")
var underscore = require('underscore')
import 'custom/modal-remote-form'
import 'custom/invoices'
import 'jquery_nested_form'
import "../stylesheets/application"
import 'select2'
import 'block-ui'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'

const images = require.context('../images', true)
document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    })
});
Rails.start()
Turbolinks.start()
ActiveStorage.start()
window.jQuery = $;
window.$ = $;
window._ = underscore;
$.fn.select2.defaults.set( "theme", "bootstrap" );


window.loader = function(div){
  str = '<div class="d-flex justify-content-center mt-5">\
          <div class="spinner-border" role="status">\
            <span class="sr-only">Loading...</span>\
          </div>\
        </div>';
  if($(div).length > 0){
    $(div).append(str);
  }
  return str;
};

window.blockUI = function($div) {
  $div = $div || $("body");
  $div.css("overflow", "hidden");
  if ($div.length > 0) {
    $div.each(function () {
      var str = '<div class="block_ui">';
      if ($(this).height() > 50) {
        str += loader();
      }
      str += "</div>";
      $(this).prepend(str);
    });
  }
};

window.unblockUI = function ($div) {
  $div = $div || $("body");
  $div.css("overflow", "");
  if ($div.selector == "body") {
    $(".block_ui").remove();
  } else {
    $div.find(".block_ui").remove();
  }
};
