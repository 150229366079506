import 'underscore'
$(document).on('turbolinks:load', function(){
  if(window.history && typeof window.history.pushState === "function"){
    var href = window.location.href;
    if(href.indexOf("remote-state=") != -1){
      var param = getParamFromURL(window.location.href, "remote-state");
      modal_remote_form_link_click_handler(param);
    }
  }
});

$(document).on("click", '.modal-remote-form-link', function(e){
  e.preventDefault();
  if ($(this).attr("href")){
    var url = $(this).attr("href");
  } else {
    var url = $(this).parents('form').attr('action') + '?' + $(this).parents('form').serialize();
  }
  modal_remote_form_link_click_handler(url);
});

var handle_remote_pushstate = function(){
  if(window.history && typeof window.history.pushState === "function"){
    $("#modal-remote-form-inner").off("hide.bs.modal", handle_remote_pushstate);
    var href = removeParamFromURL(window.location.href, "remote-state");
    var pageReload = getParamFromURL(window.location.href, "page_reload");
    window.history.pushState(null, null, href);
    if(pageReload) { window.location.reload(); }
  }
}

var modal_remote_form_link_click_handler = function(remote_href){
  $.blockUI({message:  '<h4>Please wait...</h4>'});
  if(!_.isEmpty(remote_href) && remote_href != "javascript:;" && remote_href != "javascript:void(0);"){
    if(window.history && typeof window.history.pushState === "function"){
      var href = window.location.href;
      $('.modal-backdrop').remove()
      var href = removeParamFromURL(href, "remote-state");
      if(href.indexOf("?") == -1){
        href += "?";
      }else{
        href += "&";
      }
      href += "remote-state=" + remote_href;
      window.history.pushState(null, null, href);
    }
    $.ajax({
      url: decodeURIComponent(remote_href),
      type: "GET",
      dataType: "html",
      success: function(one){
        if($("#modal-remote-form-container").length == 0){
          $('body').append('<div id="modal-remote-form-container"></div>');
        }
        $("#modal-remote-form-container").html(one);
        $("#modal-remote-form-inner").modal({
          backdrop: 'static',
          show: true,
          keyboard: false,
          focus: true
        });
        $("#modal-remote-form-container [data-toggle='tooltip']").tooltip();
        if(window.history && typeof window.history.pushState === "function"){
          $("#modal-remote-form-inner").on("hide.bs.modal", handle_remote_pushstate);
        }
      },
      error: function(one, two, three){
        handle_remote_pushstate();
        var errors;
        if(one.responseText != undefined) {
          errors = $.parseJSON(one.responseText).errors
        }
        // global_error_handler(errors || "Error while fetching modal remote form");
      },
      complete: function(){
        $.unblockUI();
      }
    });
  }
}
$(document).on("ajax:beforeSend", '.modal-remote-form', function(event){
  $("#modal-remote-form-container .modal-body .modal-remote-form-errors").html('');
  $.blockUI({message: '<h4>Please wait...</h4>'});
});
$(document).on("ajax:success", '.modal-remote-form', function(event, two, three){
  $.unblockUI();
  var detail = event.detail;
  if(event.detail){
    var data = detail[0], status = detail[1], xhr = detail[2];
  }else if(two){
    var data = two, status = three
  }
  var customerId = data.id
  var customerName = `${data.company_name} (${data.name})`

  var message = "";
  var resource = $(event.currentTarget).attr("data-resource-type");
  if(resource){
    message += resource + " ";
  }
  if(xhr && xhr.status == 201){
    message += "created successfully";
  }else{
    message += "updated successfully";
  }
  var dismiss = $("#modal-remote-form").data("dismiss-modal");
  if(typeof dismiss != "undefined" && dimiss == false){
  }else{
    $("#modal-remote-form-inner").modal("hide");
    setTimeout(function(){
      if($("#modal-remote-form-container").length == 0){
        $('body').append('<div id="modal-remote-form-container"></div>');
      }
      $("#modal-remote-form-container").html("");
    }, 2500);
    // handle location on JSON response
    if(xhr && xhr.getResponseHeader('location')){
      window.location = xhr.getResponseHeader('location')
    }else{
      $.blockUI();
      if (window.location.href.includes('invoices')){
        refreshInvoiceData(customerId, customerName);
      } else {
        window.location = window.location;
      }
      $.unblockUI();
    }
  }
});

function refreshInvoiceData(customerId, customerName) {
  let $customer = $('#invoice_customer_id');
  if (customerId){
    if ($customer.find("option[value=" + customerId + "]").length) {
      $customer.val(customerId).trigger("change");
    } else {
      // Create the DOM option that is pre-selected by default
      var newOption = new Option(customerName, customerId, true, true);
      // Append it to the select
      $customer.append(newOption).trigger('change');
    }
  }
}

$(document).on("ajax:error", '.modal-remote-form', function(event, two, three){
  $.unblockUI();
  var detail = event.detail;
  if(event.detail){
    var data = detail[0];
  }else if(two){
    var data = two.responseJSON;
  }

  var resource = $(event.currentTarget).attr("data-resource-type");
  if($("#modal-remote-form-container .modal-body .modal-remote-form-errors").length == 0){
    $("#modal-remote-form-container .modal-body").prepend("<div class='modal-remote-form-errors'></div>");
  }
  if(data && data.errors){
    var html = '<div class="mb-3 alert alert-danger">';
    if(typeof data.errors == 'string'){
      html += '<strong>' + data.errors + '</strong>';
    } else {
      html += '<strong>The form contains ' + data.errors.length + ' errors.</strong>';
      html += '<ul class="mt-3 pl-3">';
      _.each(data.errors, function(error){
        html += '<li>' + error + '</li>';
      });
      html += '</ul>'
    }
    html += '</div>';
  } else{
    html = "We could not update the " + (resource ? resource : "record");
  }
  $("#modal-remote-form-container .modal-body .modal-remote-form-errors").html(html);
});



var removeParamFromURL = function(sourceURL, key) {
  var rtn = sourceURL.split("?")[0], param, params_arr = [], queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  if(rtn.endsWith("?")){
    rtn = rtn.slice(0, (rtn.length-1));
  }
  return rtn;
}

var getParamFromURL = function(url, key){
  var urlParams = {};
  var key = key || "";
  url.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function($0, $1, $2, $3) {
      urlParams[$1] = $3;
    }
  );
  if(key){
    return urlParams[key];
  }else{
    return urlParams;
  }
}
